import React from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@mui/material'
import { Close } from '@mui/icons-material'

export function SubscriptionButton({ href, onClick, ...props }: any) {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClick = () => window.open(href, '_blank')?.focus()

    const renderButton = (onClick: any) => {
        const style = {
            bgcolor: 'background.yellow',
            borderRadius: 5,
            color: 'primary.main',
            fontSize: { md: 20 },
            fontWeight: 800,
            py: { md: 2 },
            px: { md: 5 },
            ':hover': { bgcolor: 'third.light' },
            ...props.sx,
        }

        return (
            <Button onClick={onClick} {...props} sx={style}>
                Inscreva-se
            </Button>
        )
    }

    const renderModal = () => {
        const modalStyle = {
            bgcolor: 'background.main',
            borderRadius: '30px',
            boxShadow: 24,
            left: '50%',
            maxWidth: 'calc(100vw - 100px)',
            p: 4,
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '500px',
            '& h4, & p, & span, & svg': { color: 'primary.main' },
        }

        const closeStyle = { mr: '10px', mt: '10px', position: 'absolute', right: 0, top: 0 }

        return (
            <Modal open={open} onClose={handleClose}>
                <Box sx={modalStyle}>
                    <IconButton onClick={handleClose} sx={closeStyle}>
                        <Close/>
                    </IconButton>

                    <Typography variant="h4" sx={{ mb: 4, mt: -1 }}>
                        Inscrições
                    </Typography>

                    <Typography>As inscrições serão somente antecipadas pelo site.</Typography>
                    <Typography>Não haverá inscrições no dia do evento.</Typography>


                    { href ? (
                        <Typography sx={{ mt: 4 }}>
                            {renderButton(handleClick)}
                        </Typography>
                    ) : (
                        <>
                            <Typography sx={{ fontWeight: 500, mt: 4 }}>
                                As inscrições ainda não estão abertas.
                            </Typography>

                            <Typography sx={{ fontWeight: 500 }}>
                                Mas fique atento, elas podem abrir a qualquer momento!
                            </Typography>
                        </>
                    ) }
                </Box>
            </Modal>
        )
    }

    return (
        <>
            {renderModal()}
            {renderButton(() => { onClick?.(); handleOpen() })}
        </>
    )
}
