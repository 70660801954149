import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { Map, StyledBox } from '../components'

export function Contact() {
    return <StyledBox disableGutters lowGutters sx={{ alignContent: 'center', display: 'flex', pt: { xs: 5, md: 0 } }}>
        <Grid alignItems="center" container spacing={2}>
            <Grid display="flex" item xs={12} md={6}>
                <Box sx={{ pl: { xs: 0, md: 20 } }}>
                    <Typography variant="h3">Contato</Typography>

                    <Typography>
                        Entre em contato conosco
                    </Typography>

                    <Typography sx={{ pt: 2 }}>
                        O Mini Race Brasil de Motocross tem o objetivo de fomentar a prática do esporte das categorias
                        de base, criando novos atletas.
                    </Typography>

                    <Typography sx={{ pt: 2 }}>
                        Escritório em

                        <Typography variant="caption" sx={{
                            color: 'secondary.main',
                            fontSize: '1.5rem',
                            fontWeight: 700,
                            lineHeight: 1,
                            pl: 1,
                        }}>
                            São José / SC
                        </Typography>
                    </Typography>

                    <Typography sx={{ pt: 2 }}>
                        E-mail: miniracebr@gmail.com
                    </Typography>
                </Box>
            </Grid>

            <Grid display="flex" item justifyContent="center" md={6} xs={12}>
                <Map/>
            </Grid>
        </Grid>
    </StyledBox>
}
