import { SaveButton, Toolbar as DefaultToolbar } from 'react-admin'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'
import { Undo } from '@mui/icons-material'

export const transform = (data: any) => {
    delete data.id
    delete data.created_at
    delete data.updated_at

    return data
}

export const Toolbar = ({ redirect }: { redirect?: string }) => {
    const navigate = useNavigate()
    const navigateTo = redirect ?? '/admin/events'

    return (
        <DefaultToolbar>
            <SaveButton/>
            <Button onClick={() => navigate(navigateTo)} sx={{ ml: 'auto' }}><Undo sx={{ mr: 1 }}/> Voltar</Button>
        </DefaultToolbar>
    )
}
