import * as React from 'react'
import { DateTimeInput, Edit, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from 'react-admin'
import { Toolbar, transform } from '../common'
import moment from 'moment'
import { getBase64, validateUploadImages } from '../../../utils/utils'

const customTransform = async (data: any) => {
    data.end_at = moment(new Date(data.end_at)).format('YYYY-MM-DDTHH:mm:ss')
    data.launch_at = moment(new Date(data.launch_at)).format('YYYY-MM-DDTHH:mm:ss')

    if (!data.event_banner) {
        delete data.event_banner
    }

    if (!data.background_desktop) {
        delete data.background_desktop
    }

    if (!data.background_mobile) {
        delete data.background_mobile
    }

    if (data.ebook_file && typeof data.ebook_file === 'object') {
        data.ebook_file = await getBase64(data.ebook_file)
    } else {
        delete data.ebook_file
    }

    return transform(data)
}

export const EditEvent = () => {
    const validateParams = [
        { field: 'event_banner', required: false },
        { field: 'background_desktop', required: false },
        { field: 'background_mobile', required: false },
    ]

    return (
        <Edit mutationMode="pessimistic" title="Evento" transform={customTransform}>
            <SimpleForm toolbar={<Toolbar/>} validate={validateUploadImages(validateParams)}>
                <TextInput variant="outlined" label="Sub título" source="subtitle" fullWidth multiline required/>
                <TextInput variant="outlined" label="Descrição" source="description" fullWidth multiline required/>
                <NumberInput variant="outlined" label="Edição" source="edition" fullWidth required/>
                <DateTimeInput variant="outlined" label="Data de lançamento" source="launch_at" fullWidth required/>
                <DateTimeInput variant="outlined" label="Data de término" source="end_at" fullWidth required/>
                <TextInput variant="outlined" label="Link de inscrição" source="subscription" fullWidth/>

                <ImageInput variant="outlined" label={'Banner "O Evento"'} source="image_event_banner" fullWidth sx={{
                    '& .RaFileInput-removeButton': {
                        display: 'block',
                        float: 'inherit',
                        '& button': { opacity: 1 },
                    },
                    '& img': { width: '350px' },
                }}>
                    <ImageField source="event_banner" title="title" sx={{
                        display: 'flex!important',
                        justifyContent: 'center',
                        width: 'full',
                    }}/>
                </ImageInput>

                <ImageInput variant="outlined" label="Fundo para desktop" source="image_background_desktop" fullWidth sx={{
                    '& .RaFileInput-removeButton': {
                        display: 'block',
                        float: 'inherit',
                        '& button': { opacity: 1 },
                    },
                    '& img': { width: '350px' },
                }}>
                    <ImageField source="background_desktop" sx={{
                        display: 'flex!important',
                        justifyContent: 'center',
                        width: 'full',
                    }}/>
                </ImageInput>

                <ImageInput variant="outlined" label="Fundo para mobile" source="image_background_mobile" fullWidth sx={{
                    '& .RaFileInput-removeButton': {
                        display: 'block',
                        float: 'inherit',
                        '& button': { opacity: 1 },
                    },
                    '& img': { width: '350px' },
                }}>
                    <ImageField source="background_mobile" sx={{
                        display: 'flex!important',
                        justifyContent: 'center',
                        width: 'full',
                    }}/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
}
