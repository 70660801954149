import * as React from 'react'
import {
    CreateButton, Datagrid, DateField, DeleteWithConfirmButton, EditButton, List, ListContextProvider, Loading,
    TextField, useGetList, useList,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { ListActions, ListActionToolbar } from '../common'
import { Box, Typography } from '@mui/material'

const Empty = () => (
    <Box alignItems="center" textAlign="center" sx={{ mx: 'auto' }}>
        <Typography variant="h4" paragraph mt={15}>
            Ainda não há nenhum cronograma
        </Typography>

        <Typography paragraph>
            Que tal cadastrar o primeiro?
        </Typography>

        <CreateButton/>
    </Box>
)

export const ListSchedules = () => {
    const { eventId } = useParams()

    const resource = `events/${eventId}/schedules`
    const redirect = `/admin/events/${eventId}/schedules`

    const { data, isLoading } = useGetList(resource, {
        filter: undefined,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
    })
    const listContext = useList({ data, isLoading })

    if (isLoading) return <Loading/>

    listContext.data = listContext.data?.map(item => ({
        ...item,
        highlight: item.highlight ? 'Sim' : 'Não',
    }))

    const params: any = {
        actions: <ListActions/>,
        empty: <Empty/>,
        pagination: false,
        resource,
        title: 'Cronograma',
    }

    return (
        <List { ...params }>
            <ListContextProvider value={listContext}>
                <Datagrid isRowSelectable={(): any => false}>
                    <DateField label="Horário" source="timestamp" showTime={true} locales="pt-BR"/>
                    <TextField label="Título" source="title"/>
                    <TextField label="Descrição" source="description"/>
                    <TextField label="Destaque" source="highlight"/>

                    <ListActionToolbar>
                        <EditButton/>
                        <DeleteWithConfirmButton mutationMode="optimistic" redirect={redirect} resource="schedules"/>
                    </ListActionToolbar>
                </Datagrid>
            </ListContextProvider>
        </List>
    )
}
