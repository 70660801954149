import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { App } from './App'
import React from 'react'
import CustomAdmin from './app/admin/Admin'

export default function Router() {
    return <BrowserRouter>
        <Routes>
            <Route path="/admin/*" element={<CustomAdmin/>}/>
            <Route path="*" element={<App/>}/>
        </Routes>
    </BrowserRouter>
}
