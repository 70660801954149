import Axios, { AxiosInstance } from 'axios'
import moment from 'moment'
import { UrlProvider } from '../../utils/urlProvider'
import { EventDto, ImageDto, ScheduleDto, SettingsDto, TermDto } from '../dtos'
import { ResultDto } from '../dtos/result.dto'
import { replaceImageFormat } from '../../utils/utils'

class ApiService {
    private client: AxiosInstance

    constructor() {
        this.client = Axios.create({ baseURL: UrlProvider.api })
    }

    async getSettings(): Promise<SettingsDto> {
        const { data } = await this.client.get(`/settings`)

        return {
            event: data.current_event_id,
        }
    }

    async getEvent(eventId: number): Promise<EventDto|undefined> {
        const { data } = await this.client.get(`/events/${eventId}`)
        return this.eventFormatter(data)
    }

    async getEventCarousel(eventId: number): Promise<ImageDto[]> {
        const { data } = await this.client.get(`/events/${eventId}/images/carousel`)
        return data.map(this.imageFormatter)
    }

    async getEventGallery(eventId: number): Promise<ImageDto[]> {
        const { data } = await this.client.get(`/events/${eventId}/images/gallery`)
        return data.map(this.imageFormatter)
    }

    async getEventSchedules(eventId: number): Promise<ScheduleDto[]> {
        const { data } = await this.client.get(`/events/${eventId}/schedules`)
        return data.map((data: any) => ({ ...data, timestamp: this.datetimeFormatter(data.timestamp) }))
    }

    async getEventTerms(eventId: number): Promise<TermDto|undefined> {
        const {
            data: {
                id,
                regulation,
                disclaimer,
            },
        } = await this.client.get(`/events/${eventId}/terms`)
        return id ? { id, regulation, disclaimer } : undefined
    }

    async getEventResults(eventId: number): Promise<ResultDto[]> {
        const { data } = await this.client.get(`/events/${eventId}/results`)
        return data.map((result: any) => ({ ...result, document: false })).map(this.resultFormatter)
    }

    async getResultDoc(resultId: number): Promise<ResultDto> {
        const { data } = await this.client.get(`/results/${resultId}`)
        return this.resultFormatter(data)
    }

    private datetimeFormatter(date: string): moment.Moment {
        return moment(date, 'YYYY-MM-DD HH:mm:ss')
    }

    private eventFormatter(event: any): EventDto|undefined {
        if (!event) {
            return undefined
        }

        const {
            background_desktop: backgroundDesktop,
            background_mobile: backgroundMobile,
            description,
            edition,
            end_at: endAt,
            event_banner: eventBanner,
            id,
            launch_at: launchAt,
            term_id: termId,
            subscription,
            subtitle,
        } = event

        return {
            backgroundDesktop,
            backgroundMobile: backgroundMobile ?? backgroundDesktop,
            banner: replaceImageFormat(eventBanner)!,
            description,
            edition,
            endAt: this.datetimeFormatter(endAt),
            id,
            launchAt: this.datetimeFormatter(launchAt),
            subscription,
            subtitle,
            termId,
        }
    }

    private imageFormatter(image: any): ImageDto {
        const { alt, event_id: event, id, order, title, type, url, year } = image
        return { alt, event, id, order, title, type, url: replaceImageFormat(url)!, year }
    }

    private resultFormatter(result: any): ResultDto {
        const { document, five, four, id, image, one, order, three, title, two } = result
        return { document, five, four, id, image: replaceImageFormat(image), one, order, three, title, two }
    }
}

const instance = new ApiService()
export default instance
