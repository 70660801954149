import * as React from 'react'
import { Create, Loading, SelectInput, SimpleForm, useGetList, useGetOne, useList } from 'react-admin'
import { Toolbar, transform } from '../common'

export function Settings() {
    const { data: events, isLoading: isLoadingEvents } = useGetList('events', {
        filter: undefined,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
    })

    const { data: settings, isLoading: isLoadingSettings, isError: settingsError } = useGetOne('settings', {
        id: 1,
    })

    const listContext = useList({ data: events, isLoading: isLoadingEvents })

    if (isLoadingEvents || (isLoadingSettings && !settingsError)) return <Loading/>

    const choices = listContext.data?.map(item => ({
        id: item.id, name: `Edição ${item.edition}`,
    })) ?? []

    const params = {
        redirect: '/admin/events',
        resource: 'settings',
        title: 'Reconfigures',
        transform,
    }

    return (
        <Create {...params}>
            <SimpleForm defaultValues={settings} toolbar={<Toolbar/>}>
                <SelectInput variant="outlined" choices={choices} fullWidth label="Evento" required source="current_event_id"/>
            </SimpleForm>
        </Create>
    )
}
