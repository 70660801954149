import React from 'react'
import { Box, Typography } from '@mui/material'
import { DownloadPdfButton, Loading, StyledBox } from '../components'
import { TermDto } from '../dtos'

export function Regulation({ terms, loading }: { terms: TermDto|undefined, loading: boolean }) {
    if (loading) return <Loading/>

    return (
        <StyledBox>
            <Typography variant="h3">
                Regulamento
            </Typography>

            <Box sx={{
                display: terms ? 'grid' : 'none',
                gap: 4,
                justifyItems: 'center',
                mt: 10,
            }}>
                {terms?.regulation && (
                    <DownloadPdfButton base64={terms.regulation} fileName="regulamento">
                        Regulamento
                    </DownloadPdfButton>
                )}

                {terms?.disclaimer && (
                    <DownloadPdfButton base64={terms.disclaimer} fileName="termo_de_responsabilidade_e_direitos_de_imagem">
                        Termo de responsabilidade e cessão de direitos de imagem
                    </DownloadPdfButton>
                )}
            </Box>
        </StyledBox>
    )
}
