import React, { useCallback } from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'

const center = { lat: -27.567927500567336, lng: -48.6594331765624 }

export function Map() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
    })

    const onLoad = useCallback((map: any) => {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
    }, [])

    return isLoaded ? (
        <GoogleMap
            center={center}
            mapContainerStyle={{ height: '350px', maxWidth: 'calc(100vw - 1rem)', width: '500px' }}
            onLoad={onLoad}
            zoom={15}
            options={{ maxZoom: 15, minZoom: 5 }}
        >
            <></>
        </GoogleMap>
    ) : <></>
}
