import React from 'react'
import { Loading as DefaultLoading } from 'react-admin'
import { Box } from '@mui/material'
import themeConfig from '../config/themeConfig'

export function Loading() {
    const params = {
        sx: {
            alignItems: 'center',
            background: themeConfig.palette.linearGradient.main,
            justifyContent: 'center',
            height: '100vh',
            position: 'absolute',
            top: 0,
            width: '100vw',
            zIndex: 100,
        },
    }

    return <Box {...params}>
        <DefaultLoading
            className="Loading"
            loadingPrimary="Carregando"
            loadingSecondary="A página está carregando, aguarde um momento"
        />
    </Box>
}
