import * as React from 'react'
import {
    CreateButton, Datagrid, DeleteWithConfirmButton, EditButton, ImageField, List, ListContextProvider, Loading,
    TextField, useGetList, useList, useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { ListActions, ListActionToolbar } from '../common'
import { Box, Typography } from '@mui/material'

const Empty = () => (
    <Box alignItems="center" textAlign="center" sx={{ mx: 'auto' }}>
        <Typography variant="h4" paragraph mt={15}>
            Ainda não há nenhuma imagem
        </Typography>

        <Typography paragraph>
            Que tal cadastrar a primeira?
        </Typography>

        <CreateButton/>
    </Box>
)

export const ListImages = () => {
    const { eventId } = useParams()
    const translate = useTranslate()

    const resource = `events/${eventId}/images`
    const redirect = `/admin/events/${eventId}/images`

    const { data, isLoading } = useGetList(resource, {
        filter: undefined,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
    })
    const listContext = useList({ data, isLoading })

    if (isLoading) return <Loading/>

    listContext.data = listContext.data?.map(item => ({
        ...item,
        type: translate(`image.type.${item.type}`),
    }))

    const params: any = {
        actions: <ListActions/>,
        empty: <Empty/>,
        pagination: false,
        resource,
        title: 'Imagens',
    }

    return (
        <List {...params}>
            <ListContextProvider value={listContext}>
                <Datagrid isRowSelectable={(): any => false}>
                    <TextField label="Título" source="title"/>
                    <TextField label="Descrição" source="alt"/>
                    <TextField label="Tipo" source="type"/>
                    <TextField label="Ordem" source="order"/>
                    <ImageField textAlign="center" source="url" sx={{ '& img': { height: '50px' } }} title="title"/>
                    <ListActionToolbar>
                        <EditButton/>
                        <DeleteWithConfirmButton mutationMode="optimistic" redirect={redirect} resource="images"/>
                    </ListActionToolbar>
                </Datagrid>
            </ListContextProvider>
        </List>
    )
}
