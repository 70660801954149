import { CreateButton, TopToolbar } from 'react-admin'
import { Box } from '@mui/material'
import * as React from 'react'

export const ListActions = () => (
    <TopToolbar>
        <CreateButton/>
    </TopToolbar>
)

export const ListActionToolbar = ({ children, ...props }: any) => (
    <Box sx={
        {
            display: 'flex',
            gap: '.25rem',
            justifyContent: 'flex-end',
            '& .MuiButton-root': {
                fontSize: '0',
                minWidth: 'inherit',
                '& .MuiButton-startIcon': {
                    margin: '0!important',
                },
            },
        }
    } {...props}>{children}</Box>
)
