import { orange, red } from '@mui/material/colors'
import { DefaultTheme } from '@mui/system'

const colors = {
    black: '#000000',
    brown: '#766010',
    darkGray: '#111827',
    red: '#ED3623',
    gray: '#6B7280',
    lightGray: '#959CA9',
    lightYellow: '#F6E17D',
    lightRed: '#CD836A',
    white: '#FFFFFF',
    yellow: '#FBCC0C',
}

const text = {
    main: colors.darkGray,
    primary: colors.darkGray,
    secondary: colors.gray,
    disabled: colors.lightGray,
}

const themeConfig: DefaultTheme|any = {
    palette: {
        text,
        primary: {
            main: colors.darkGray,
            contrastText: colors.white,
        },
        secondary: {
            main: colors.red,
            dark: colors.red,
            light: colors.yellow,
            contrastText: colors.white,
        },
        background: {
            blackTransparent: `${colors.black}99!important`,
            dark: colors.black,
            main: colors.white,
            red: colors.red,
            yellow: colors.yellow,
        },
        linearGradient: {
            main: `linear-gradient(to top right, ${colors.red} -150%, ${colors.yellow})`,
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    status: {
        danger: orange,
    },
    typography: {
        fontFamily: [
            'Circular',
            'ui-sans-serif',
            'system-ui',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
            '"Noto Color Emoji"',
        ].join(','),
    },
}

export default themeConfig
