import * as React from 'react'
import { BooleanInput, DateTimeInput, Edit, SimpleForm, TextInput } from 'react-admin'
import { Toolbar, transform } from '../common'
import { useParams } from 'react-router-dom'
import moment from 'moment'

const customTransform = (data: any) => {
    data.timestamp = moment(data.timestamp).format('YYYY-MM-DDTHH:mm:ss')
    return transform(data)
}

export const EditSchedule = () => {
    const { eventId } = useParams()
    const redirect = `/admin/events/${eventId}/schedules`

    const params = {
        mutationMode: 'pessimistic',
        redirect,
        resource: 'schedules',
        title: 'Edição de Cronograma',
        transform: customTransform,
    } as any

    return (
        <Edit {...params}>
            <SimpleForm toolbar={<Toolbar redirect={redirect}/>}>
                <DateTimeInput variant="outlined" label="Horário" source="timestamp" fullWidth required/>
                <TextInput variant="outlined" label="Título" source="title" fullWidth required/>
                <TextInput variant="outlined" label="Descrição" source="description" fullWidth/>
                <BooleanInput variant="outlined" label="Destaque" source="highlight" fullWidth/>
            </SimpleForm>
        </Edit>
    )
}
