import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import { Typography } from '@mui/material'

export function Footer() {
    return (
        <AppBar position="sticky" sx={{ bgcolor: 'background.dark', height: '64px' }}>
            <Container sx={{ height: '64px', maxWidth: 'xl' }}>
                <Toolbar disableGutters>
                    <Typography width="100%">
                        Copyright &copy; {new Date().getFullYear()} Mini Race Brasil
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
