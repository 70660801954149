import * as React from 'react'
import { BooleanInput, Create, DateTimeInput, SimpleForm, TextInput } from 'react-admin'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { Toolbar } from '../common'

const customTransform = (data: any) => {
    data.timestamp = moment(data.timestamp).format('YYYY-MM-DDTHH:mm:ss')
    return data
}

export const CreateSchedule = () => {
    const { eventId } = useParams()

    const redirect = `/admin/events/${eventId}/schedules`

    const params = {
        redirect,
        resource: `events/${eventId}/schedules`,
        title: 'Cadastrar Cronograma',
        transform: customTransform,
    }

    return (
        <Create {...params}>
            <SimpleForm toolbar={<Toolbar redirect={redirect}/>}>
                <DateTimeInput variant="outlined" label="Horário" source="timestamp" defaultValue={new Date()} fullWidth required/>
                <TextInput variant="outlined" label="Título" source="title" fullWidth required/>
                <TextInput variant="outlined" label="Descrição" source="description" fullWidth/>
                <BooleanInput variant="outlined" label="Destaque" source="highlight" fullWidth/>
            </SimpleForm>
        </Create>
    )
}
