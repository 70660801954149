import * as React from 'react'
import { Edit, NumberInput, SelectInput, SimpleForm, TextInput, useTranslate } from 'react-admin'
import { Toolbar, transform } from '../common'
import { useParams } from 'react-router-dom'

const customTransform = (data: any) => {
    delete data.url
    return transform(data)
}

export const EditImage = () => {
    const translate = useTranslate()
    const { eventId } = useParams()

    const redirect = `/admin/events/${eventId}/images`

    const choices = [
        { id: 'carousel' },
        { id: 'gallery' },
    ].map(item => ({
        ...item,
        name: translate(`image.type.${item.id}`),
    }))

    const params = {
        mutationMode: 'pessimistic',
        redirect,
        resource: 'images',
        title: 'Imagem',
        transform: customTransform,
    } as any

    return (
        <Edit {...params}>
            <SimpleForm toolbar={<Toolbar redirect={redirect}/>}>
                <TextInput variant="outlined" label="Título" source="title" fullWidth required/>
                <TextInput variant="outlined" label="Descrição" source="alt" fullWidth required/>
                <SelectInput variant="outlined" label="Tipo" source="type" choices={choices} fullWidth required/>
                <NumberInput variant="outlined" label="Ordem" source="order" fullWidth/>
                <NumberInput variant="outlined" label="Ano" source="year" fullWidth/>
            </SimpleForm>
        </Edit>
    )
}
