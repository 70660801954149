import React, { useEffect, useState } from 'react'
import { Box, experimental_sx as sx, Grid, Typography } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { Loading } from '../components'
import { countDown } from '../../utils/utils'
import { styled } from '@mui/styles'
import { EventDto, ImageDto } from '../dtos'
import { DownloadButton } from '../components'

const TimerField = styled(Typography)(sx({
    color: 'primary.contrastText',
    fontSize: { xs: '2.5rem!important', md: '3.5rem!important' },
    fontWeight: '800!important',
    textShadow: '0 0 15px #000',
    '&:nth-child(even)': {
        fontSize: { xs: '0.9rem!important', md: '1.5rem!important' },
    },
}) as any)

const TimerGrid = styled(Box)(sx({
    borderRadius: 10,
    display: 'flex',
    gap: { xs: 3, md: 5 },
    height: 'fit-content',
    justifySelf: 'center',
    p: { xs: 2, md: 3 },
    width: 'fit-content',
}) as any)

export function Home({
    carousel,
    event,
    loading,
}: { carousel: ImageDto[], event: EventDto|undefined, loading: boolean }) {
    const [days, setDays] = useState<string>('00')
    const [hours, setHours] = useState<string>('00')
    const [minutes, setMinutes] = useState<string>('00')
    const [seconds, setSeconds] = useState<string>('00')

    useEffect(() => {
        if (!loading && event) {
            countDown({ setDays, setHours, setMinutes, setSeconds }, event.launchAt)
        }
    }, [event, loading])

    const renderTimer = () => {
        if (loading) return <Loading/>

        return <TimerGrid>
            <Grid item>
                <Box>
                    <TimerField>{days}</TimerField>
                    <TimerField>Dias</TimerField>
                </Box>
            </Grid>

            <Grid item>
                <Box>
                    <TimerField>{hours}</TimerField>
                    <TimerField>Horas</TimerField>
                </Box>
            </Grid>

            <Grid item>
                <Box>
                    <TimerField>{minutes}</TimerField>
                    <TimerField>Minutos</TimerField>
                </Box>
            </Grid>

            <Grid item>
                <Box>
                    <TimerField>{seconds}</TimerField>
                    <TimerField>Segundos</TimerField>
                </Box>
            </Grid>
        </TimerGrid>
    }

    const renderCarousel = () => (
        <Box sx={{ display: 'flex', minHeight: '145px', minWidth: '300px' }}>
            <Carousel
                animation="slide"
                autoPlay={true}
                className="Carousel"
                duration={1000}
                interval={5000}
                navButtonsAlwaysVisible={false}
                sx={{
                    width: '100%',
                    '& button': {
                        boxShadow: '0 0 5px #fff',
                        zIndex: 50,
                    },
                    '& img': {
                        maxHeight: 'calc(55vw * (110 / 300))',
                        maxWidth: '55vw',
                    },
                    '& [aria-label=Next], & [aria-label=Previous]': {
                        display: 'none',
                    },
                }}
            >
                {carousel.map(image => {
                    const params = {
                        height: 110,
                        key: image.id,
                        src: image.url,
                        title: image.title,
                        width: 300,
                    }

                    return <img alt={image.alt} {...params}/>
                })}
            </Carousel>
        </Box>
    )

    const renderEbook = () => (
        <Box sx={{ display: 'flex', height: 'fit-content', maxWidth: 'calc(100vw - 80px)', py: { xs: 2, md: 4 } }}>
            <DownloadButton download="EBOOK Pais de atleta.pdf" href="/ebook.pdf" sx={{ width: '220px' }}>
                E book bônus para pais dos pilotos
            </DownloadButton>
        </Box>
    )

    return <Box sx={{
        bgcolor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        minHeight: { xs: 'calc(100vh - 95px - 64px)', md: 'calc(100vh - 199px - 64px)' },
        justifyContent: 'center',
        position: 'relative',
        '&::after': {
            backgroundImage: { xs: `url(${event?.backgroundMobile})`, md: `url(${event?.backgroundDesktop})` },
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            content: '""',
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: -1,
        },
    }}>
        <Box sx={{ alignItems: 'center', display: 'grid', justifyItems: 'center', '& *': { zIndex: 3 } }}>
            {renderTimer()}
            {renderCarousel()}
            {renderEbook()}
        </Box>
    </Box>
}
