// noinspection JSUnusedGlobalSymbols

import { UrlProvider } from './utils/urlProvider'

const authProvider = {
    login: ({
        email,
        password,
    }: { email: string, password: string }) => {
        const request = new Request(`${UrlProvider.api}/login`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
            }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })

        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText)
            }

            return response.json()
        }).then(auth => {
            localStorage.setItem('auth', auth.token)
        }).catch(() => {
            throw new Error('Network error')
        })
    },
    checkError: (error: Error|any) => {
        const status = error?.status

        if (status === 401 || status === 403 || status === 498) {
            localStorage.removeItem('auth')
            return Promise.reject({ message: false })
        }

        return Promise.resolve()
    },
    checkAuth: () => {
        const auth = localStorage.getItem('auth')

        if (!auth) {
            return Promise.reject({ redirectTo: '/admin/login' })
        }

        const request = new Request(`${UrlProvider.api}/verify`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${auth}`,
            }),
        })

        return fetch(request).then(response => {
            if (response.status < 200 || response.status >= 300) {
                return Promise.reject({ redirectTo: '/admin/login' })
            }

            return Promise.resolve()
        }).catch(() => {
            return Promise.reject({ redirectTo: '/admin/login' })
        })
    },
    logout: () => {
        localStorage.removeItem('auth')
        return Promise.resolve()
    },
    getIdentity: () => {
        if (!localStorage.getItem('auth')) {
            return Promise.reject()
        }

        return Promise.resolve({
            id: 'ADMIN',
            fullName: 'Administrador',
            avatar: '',
            token: localStorage.getItem('auth'),
        })
    },
    getPermissions: () => {
        return Promise.resolve('admin')
    },
}

export default authProvider
