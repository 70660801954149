import * as React from 'react'
import {
    CreateButton, Datagrid, DeleteWithConfirmButton, EditButton, List, ListContextProvider, Loading, TextField,
    useGetList, useList,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { ListActions, ListActionToolbar } from '../common'
import { Box, Typography } from '@mui/material'

const Empty = () => (
    <Box alignItems="center" textAlign="center" sx={{ mx: 'auto' }}>
        <Typography variant="h4" paragraph mt={15}>
            Ainda não há nenhum resultado
        </Typography>

        <Typography paragraph>
            Que tal cadastrar o primeiro?
        </Typography>

        <CreateButton/>
    </Box>
)

export const ListResults = () => {
    const { eventId } = useParams()

    const resource = `events/${eventId}/results`
    const redirect = `/admin/events/${eventId}/results`

    const { data, isLoading } = useGetList(resource, {
        filter: undefined,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'DESC' },
    })
    const listContext = useList({ data, isLoading })

    if (isLoading) return <Loading/>

    const params: any = {
        actions: <ListActions/>,
        empty: <Empty/>,
        pagination: false,
        resource,
        title: 'Resultado',
    }

    return (
        <List {...params}>
            <ListContextProvider value={listContext}>
                <Datagrid isRowSelectable={(): any => false}>
                    <TextField label="Título" source="title"/>
                    <TextField label="Ordem" source="order"/>

                    <ListActionToolbar>
                        <EditButton/>
                        <DeleteWithConfirmButton mutationMode="optimistic" redirect={redirect} resource="results"/>
                    </ListActionToolbar>
                </Datagrid>
            </ListContextProvider>
        </List>
    )
}
