import imgurService from '../app/services/imgur.service'
import moment from 'moment'

type CountDownState = { setDays: Function, setHours: Function, setMinutes: Function, setSeconds: Function, }

export function countDown(state: CountDownState, date: moment.Moment) {
    const interval = setInterval(async () => {
        const countDownDate = date.toDate().getTime()
        const now = Date.now()
        const distance = countDownDate - now

        if (distance < 0) {
            clearInterval(interval)
            return
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24))
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((distance % (1000 * 60)) / 1000)

        state.setDays(days < 10 ? `0${days}` : `${days}`)
        state.setHours(hours < 10 ? `0${hours}` : `${hours}`)
        state.setMinutes(minutes < 10 ? `0${minutes}` : `${minutes}`)
        state.setSeconds(seconds < 10 ? `0${seconds}` : `${seconds}`)
    }, 1000)
}

export function validateUploadImages(images: { field: string, required?: boolean }[]) {
    return async (values: any) => {
        const errors: any = {}

        for (const { field, required } of images) {
            const currentErrors = await validateUploadImage(field, required ?? true)(values)

            for (const key of Object.keys(currentErrors)) {
                errors[key] = currentErrors[key]
            }
        }

        return errors
    }
}

export function validateUploadImage(field: string, required: boolean = true) {
    return async (values: any) => {
        const errors: any = {}

        if (!values[`image_${field}`]) {
            return required ? { [`image_${field}`]: 'image.message.imageRequired' } : {}
        }

        const response = await imgurService.uploadImage(values[`image_${field}`])

        if (!response.success) {
            errors[`image_${field}`] = 'image.message.uploadFailed'
        }

        values[field] = response.data.link
        delete values[`image_${field}`]

        return errors
    }
}

export async function getBase64(data: any): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(data.rawFile)

        reader.onload = () => {
            const base64 = reader.result as string
            resolve(base64.replace(/data:\w+\/\w+;base64,/, ''))
        }
        reader.onerror = error => reject(error)
    })
}

export function replaceImageFormat(url: string|undefined) {
    return url?.replace(/(\.jpg|\.jpeg|\.png)/, '.webp')
}
