import React from 'react'
import { Box, experimental_sx as sx, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { ScheduleDto } from '../dtos'
import { Loading, StyledBox } from '../components'
import { styled } from '@mui/styles'

const CustomBox = styled(Box)(sx({
    bgcolor: 'background.main',
    display: 'flex',
    p: { xs: 0, md: 5 },

    '& table': {
        '& tr': {
            display: { xs: 'grid', md: 'table-row' },
            gap: { xs: 1, md: 0 },
            gridTemplateColumns: { xs: 'repeat(1,minmax(0,1fr));', md: 'none' },

            '& td': {
                display: { xs: 'grid', md: 'table-cell' },
                px: { xs: 1, md: 5 },
                py: { xs: 3, md: 3 },

                '&:nth-child(odd)': {
                    borderBottomWidth: { xs: 0, md: '1px' },
                    display: { xs: 'flex', md: 'table-cell' },
                    flex: { xs: 'inherit', md: 'none' },
                    gap: 1,
                    justifyContent: 'center',
                    paddingBottom: { xs: 0, md: 3 },

                    '& p': {
                        fontSize: '0.9rem',
                    },
                },
                '&:nth-child(even)': {
                    flexGrow: { xs: 0, md: 1 },
                    justifyItems: 'center',
                    paddingTop: { xs: 0, md: 3 },

                    '& p': {
                        px: { xs: 3, md: 0 },
                        width: { xs: 'fit-content', md: 'inherit' },

                        '&:nth-child(odd)': {
                            fontSize: '1.2rem',
                        },
                        '&:nth-child(even)': {
                            fontSize: '0.9rem',
                        },
                    },
                },

                '& p, & :is(h1, h2, h3, h4, h5, h6)': { textAlign: 'center' },
            },
        },
    },
}) as any)

export function Schedule({ schedules, loading }: { schedules: ScheduleDto[], loading: boolean }) {
    if (loading) return <Loading/>

    const renderSchedules = () => {
        return schedules.map(schedule => (
            <TableRow key={schedule.id}>
                <TableCell>
                    <Typography>
                        {schedule.timestamp.format('DD/MM/YYYY')}
                    </Typography>

                    {!schedule.highlight && (
                        <Typography>
                            {schedule.timestamp.format('HH:mm')}
                        </Typography>
                    )}
                </TableCell>

                <TableCell>
                    <Typography variant={schedule.highlight ? 'h5' : 'inherit'}>
                        {schedule.title}
                    </Typography>

                    <Typography>
                        {schedule.description}
                    </Typography>
                </TableCell>
            </TableRow>
        ))
    }

    return (
        <StyledBox>
            <Typography align="center" variant="h3">
                Cronograma do Evento
            </Typography>

            <CustomBox>
                <Table>
                    <TableBody>
                        {renderSchedules()}
                    </TableBody>
                </Table>
            </CustomBox>
        </StyledBox>
    )
}
