import * as React from 'react'
import simpleRestProvider from 'ra-data-simple-rest'
import { Login } from './Login'
import { Admin, CustomRoutes, defaultTheme, fetchUtils, Resource } from 'react-admin'
import { CreateEvent, EditEvent, ListEvents, ShowEvent } from './events'
import authProvider from '../../authProvider'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import ptBrMessages from 'ra-language-pt-br'
import customPtBrMessages from './i18n/pt-br'
import { Event } from '@mui/icons-material'
import { CreateImage, EditImage, ListImages } from './images'
import { CreateSchedule, EditSchedule, ListSchedules } from './schedules'
import { Route } from 'react-router-dom'
import { Settings } from './settings'
import Layout from './Layout'
import { DefaultTheme } from '@mui/system'
import { UrlProvider } from '../../utils/urlProvider'
import { Terms } from './terms'
import { CreateResult, EditResult, ListResults } from './results'

declare type HttpClientResponse = {
    body: string;
    headers: Headers;
    json: any;
    status: number;
}

const messages: { [key: string]: any } = {
    'pt-br': customPtBrMessages(ptBrMessages),
}

const i18nProvider = polyglotI18nProvider(locale => messages[locale], 'pt-br')

const theme: DefaultTheme = {
    ...defaultTheme,
    components: {
        ...defaultTheme.components,
        MuiTextField: {
            defaultProps: {
                variant: 'outline' as const,
            },
        },
    },
}

export default function CustomAdmin() {
    const httpClient = async (url: string, options: any = {}) => {
        const { token } = await authProvider.getIdentity()

        if (!options.headers) {
            options.headers = new Headers({
                Accept: 'application/json',
            })
        }

        options.headers.set('Authorization', `Bearer ${token}`)

        const response = await fetchUtils.fetchJson(url, options).catch((error) => {
            if ([401, 403, 404].includes(error.status)) {
                throw error
            }

            if (error.status !== 404) {
                console.error('Error when call api', error)
            }
        })

        return response ?? options as HttpClientResponse
    }

    const dataProvider = simpleRestProvider(`${UrlProvider.api}/v1`, httpClient)

    return (
        <Admin
            authProvider={authProvider}
            basename="/admin"
            dataProvider={dataProvider}
            i18nProvider={i18nProvider}
            layout={Layout}
            loginPage={Login}
            requireAuth={true}
            theme={theme}
        >
            <Resource
                create={CreateEvent}
                edit={EditEvent}
                icon={Event}
                list={ListEvents}
                name="events"
                options={{ label: 'Eventos' }}
                show={ShowEvent}
            />

            <CustomRoutes>
                {/** Event Images */}
                <Route path="/events/:eventId/images" element={<ListImages/>}/>
                <Route path="/events/:eventId/images/create" element={<CreateImage/>}/>
                <Route path="/events/:eventId/images/:id" element={<EditImage/>}/>

                {/** Event Schedules */}
                <Route path="/events/:eventId/schedules" element={<ListSchedules/>}/>
                <Route path="/events/:eventId/schedules/create" element={<CreateSchedule/>}/>
                <Route path="/events/:eventId/schedules/:id" element={<EditSchedule/>}/>

                {/** Event Terms */}
                <Route path="/events/:eventId/terms" element={<Terms/>}/>

                {/** Event Results */}
                <Route path="/events/:eventId/results" element={<ListResults/>}/>
                <Route path="/events/:eventId/results/create" element={<CreateResult/>}/>
                <Route path="/events/:eventId/results/:id" element={<EditResult/>}/>

                {/** Settings */}
                <Route path="/settings" element={<Settings/>}/>
            </CustomRoutes>
        </Admin>
    )
}
