import React, { useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material'
import { DownloadPdfButton, Loading, StyledBox } from '../components'
import { ResultDto } from '../dtos/result.dto'

export function Results({ results, loading }: { results: ResultDto[], loading: boolean }) {
    const [expanded, setExpanded] = useState<number|undefined>()

    const handleChange = (panel: number) => (event: any, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : undefined)
    }

    const renderAccordion = (result: ResultDto) => (
        <Accordion expanded={expanded === result.id} key={result.id} onChange={handleChange(result.id)}>
            <AccordionSummary aria-controls={`panel-${result.id}-content`} id={`panel-${result.id}-header`}>
                <Typography variant="h5" sx={{ color: '#000!important', fontWeight: '600!important', mx: 'auto' }}>{result.title}</Typography>
            </AccordionSummary>

            <AccordionDetails>
                <Box sx={{
                    display: 'grid',

                    '& img': {
                        mb: 2,
                        mx: 'auto',
                        maxHeight: { xs: 'calc(90vh - 64px - 95px)', md: 'calc(85vh - 64px - 199px)' },
                        maxWidth: { xs: 'calc(100vw - 64px)', md: '70vw' },
                    },
                }}>
                    {result.image && (
                        <img alt={`Resultados - ${result.title}`} src={result.image} width="calc(100vw - 64px)"/>
                    )}

                    <Box sx={{ mx: 'auto' }}>
                        {result.one && (
                            <Typography>
                                1 - {result.one}
                            </Typography>
                        )}

                        {result.two && (
                            <Typography>
                                2 - {result.two}
                            </Typography>
                        )}

                        {result.three && (
                            <Typography>
                                3 - {result.three}
                            </Typography>
                        )}

                        {result.four && (
                            <Typography>
                                4 - {result.four}
                            </Typography>
                        )}

                        {result.five && (
                            <Typography>
                                5 - {result.five}
                            </Typography>
                        )}
                    </Box>

                    <DownloadPdfButton base64={result.document} fileName={result.title} sx={{ mt: 2, mx: 'auto' }}>
                        Baixar resultados
                    </DownloadPdfButton>
                </Box>
            </AccordionDetails>
        </Accordion>
    )

    const renderResult = (result: ResultDto) => {
        const onlyTitle = result.title && !(
            result.image || result.one || result.two || result.three || result.four || result.five || result.document
        )

        if (!onlyTitle) return renderAccordion(result)

        return <Accordion expanded={false} key={result.id}>
            <AccordionSummary aria-controls={`panel-${result.id}-content`} id={`panel-${result.id}-header`}>
                <Typography variant="h5" sx={{ fontWeight: '800!important', mx: 'auto' }}>{result.title}</Typography>
            </AccordionSummary>
        </Accordion>
    }

    if (loading) return <Loading/>

    return (
        <StyledBox sx={{ '& p': { fontWeight: 400 } }}>
            <Typography variant="h3">
                Resultados
            </Typography>

            <Box>
                {results.map(renderResult)}
            </Box>
        </StyledBox>
    )
}
