import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import Router from './Router'
// @ts-ignore
import registerServiceWorker from 'react-service-worker';
import './index.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(<Router/>)

// to log results (for example: reportWebVitals(console.log))
reportWebVitals()
registerServiceWorker()
