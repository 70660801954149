import React from 'react'
import {
    Button, CreateButton, Datagrid, DateField, DeleteWithConfirmButton, EditButton, List, ListContextProvider, Loading,
    NumberField, ShowButton, TextField, useGetList, useList,
} from 'react-admin'
import { ListActions, ListActionToolbar } from '../common'
import { useNavigate } from 'react-router-dom'
import { AddAPhoto, CalendarMonth, DocumentScanner, EmojiEvents } from '@mui/icons-material'
import { Box, Tooltip, Typography } from '@mui/material'

const Empty = () => (
    <Box alignItems="center" textAlign="center" sx={{ mx: 'auto' }}>
        <Typography variant="h4" paragraph mt={15}>
            Ainda não há nenhum evento
        </Typography>

        <Typography paragraph>
            Que tal cadastrar o primeiro?
        </Typography>

        <CreateButton/>
    </Box>
)

export const Actions = () => {
    const navigate = useNavigate()
    const [eventId, setEventId] = React.useState<string|undefined>()

    const getEventDiv = (element: any) => {
        let tagName = element.tagName;

        while(tagName !== 'DIV') {
            element = element.parentElement
            tagName = element.tagName
        }

        return element
    }

    const getEventId = (event: any) => {
        if (eventId) return eventId

        const id = getEventDiv(event.target)
            ?.children[1]
            ?.href
            ?.split('/').pop(0) ?? '1'

        setEventId(id)

        return id
    }

    const goTo = (page: string) => (event: any) => {
        event.stopPropagation()
        navigate(`/admin/events/${getEventId(event)}/${page}`)
    }

    return (
        <ListActionToolbar>
            <ShowButton/>
            <EditButton/>

            <Button onClick={goTo('images')}>
                <Tooltip arrow title="Galeria de imagens">
                    <AddAPhoto/>
                </Tooltip>
            </Button>

            <Button onClick={goTo('schedules')}>
                <Tooltip arrow title="Cronograma">
                    <CalendarMonth/>
                </Tooltip>
            </Button>

            <Button onClick={goTo('terms')}>
                <Tooltip arrow title="Termos">
                    <DocumentScanner/>
                </Tooltip>
            </Button>

            <Button onClick={goTo('results')}>
                <Tooltip arrow title="Resultados">
                    <EmojiEvents/>
                </Tooltip>
            </Button>

            <DeleteWithConfirmButton/>
        </ListActionToolbar>
    )
}

export const ListEvents = () => {
    const { data, isLoading } = useGetList('events', {
        filter: undefined,
        pagination: { page: 1, perPage: 25 },
        sort: { field: 'id', order: 'ASC' },
    })
    const listContext = useList({ data, isLoading })

    if (isLoading) return <Loading/>

    const maxLength = 50

    listContext.data = listContext.data?.map(item => ({
        ...item,
        description: `${item.description.slice(0, maxLength)}${item.description.length > maxLength ? '...' : ''}`,
    }))

    const params: any = {
        actions: <ListActions/>,
        empty: <Empty/>,
        pagination: false,
        resource: 'events',
        title: 'Lista de eventos',
    }

    return (
        <List {...params}>
            <ListContextProvider value={listContext}>
                <Datagrid isRowSelectable={(): any => false}>
                    <NumberField label="Edição" source="edition"/>
                    <TextField label="Descrição" source="description"/>
                    <DateField label="Data de lançamento" source="launch_at" showTime={true} locales="pt-BR"/>
                    <Actions/>
                </Datagrid>
            </ListContextProvider>
        </List>
    )
}
