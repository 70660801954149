import ImgurClient from 'imgur'
import { Payload } from 'imgur/lib/common/types'
import { getBase64, replaceImageFormat } from '../../utils/utils'

class ImgurService {
    private client: ImgurClient

    constructor() {
        this.client = new ImgurClient({ accessToken: process.env.REACT_APP_IMGUR_ACCESS_TOKEN })
    }

    async uploadImage(file: any) {
        const request: Payload = {
            image: await getBase64(file),
            name: replaceImageFormat(file.title),
            type: 'base64',
        }

        return this.client.upload(request)
    }
}

const instance = new ImgurService()
export default instance
