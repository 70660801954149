import React, { useState } from 'react'
import { Loading, StyledBox } from '../components'
import { Box, Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { ImageDto } from '../dtos'

export function Gallery({ gallery, loading }: any) {
    const [open, setOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState<ImageDto|undefined>(undefined)

    const handleClickOpen = (item: ImageDto) => {
        setCurrentItem(item)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const groupBy = (gallery: ImageDto[]) => {
        const group: { year: number, images: ImageDto[] }[] = []
        const groupObject: { [key: number]: ImageDto[] } = []

        for (const image of gallery) {
            const year = image.year ?? 0

            if (!groupObject[year]) {
                groupObject[year] = []
            }

            groupObject[year].push(image)
        }

        for (const index in groupObject) {
            group.push({
                year: Number(index),
                images: groupObject[index],
            })
        }

        return group.sort((groupA, groupB) => groupA.year < groupB.year ? 1 : -1)
    }

    if (loading) return <Loading/>

    const renderImages = () => {
        return groupBy(gallery).map(({ images, year }) => (
            <Box key={year}>
                {year !== 0 && (
                    <Typography
                        variant="h3"
                        sx={{
                            pb: { xs: '40px!important', md: 5 },
                            pt: { xs: '40px!important', md: 5 },
                        }}
                    >
                        {year}
                    </Typography>
                )}

                <Grid sx={{
                    display: 'grid',
                    gap: '0.5rem',
                    columnGap: '0.5rem',
                    gridTemplateColumns: { xs: 'repeat(2,minmax(0,1fr))', md: 'repeat(3,minmax(0,1fr))' },
                }}>
                    {images.map((item: ImageDto) => (
                        <Grid item key={item.id} onClick={() => handleClickOpen(item)} sx={{
                            cursor: 'pointer',
                            '& img': {
                                display: 'flex',
                                height: '100%',
                                maxHeight: { xs: 'calc(100vh - 95px - 64px)', md: 'calc(100vh 199px - 64px)' },
                                objectFit: 'cover',
                                width: '100%',
                            },
                        }}>
                            <img
                                alt={item.alt}
                                loading="lazy"
                                src={item.url}
                                {...(item.title.length > 3 && { title: item.title })}
                            />
                        </Grid>
                    ))}
                </Grid>

            </Box>
        ))
    }

    const renderModal = () => {
        if (!currentItem) return

        const item = currentItem

        return (
            <Dialog onClose={handleClose} maxWidth="lg" open={open}>
                <DialogContent sx={{
                    padding: 0.5,
                    '& img': {
                        display: 'flex',
                        height: '100%',
                        objectFit: 'cover',
                        width: '100%',
                    },
                }}>
                    <img
                        alt={item.alt}
                        loading="lazy"
                        src={item.url}
                        {...(item.title.length > 3 && { title: item.title })}
                    />
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <StyledBox disableGutters lowGutters>
            {renderImages()}
            {renderModal()}
        </StyledBox>
    )
}
