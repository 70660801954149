import React, { useEffect, useState } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { SubscriptionButton } from './button'
import { EventDto } from '../dtos'

const pages = [
    { title: 'O Evento', target: '/evento' },
    { title: 'Cronograma', target: '/cronograma' },
    { title: 'Galeria', target: '/galeria' },
    { title: 'Regulamento', target: '/regulamento' },
    { title: 'Resultados', target: '/resultados' },
    { title: 'Contato', target: '/contato' },
]

const logoUrl = '/img/logo.svg'

export function Navbar({ event, loading }: { event: EventDto|undefined, loading: boolean }) {
    const navigate = useNavigate()
    const location = useLocation()

    const [anchorElNav, setAnchorElNav] = useState<null|HTMLElement>(null)
    const [currentPage, setCurrentPage] = useState('/')

    useEffect(() => {
        setCurrentPage(location.pathname)
    }, [location.pathname])

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = (target?: string) => {
        if (target && target !== '') {
            navigate(target)
        }

        setAnchorElNav(null)
    }

    const renderMobileBox = () => {
        return <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <img
                alt="logo"
                height="75px"
                onClick={() => handleCloseNavMenu('/')}
                src={logoUrl}
                style={{ padding: '10px' }}
                width="75px"
            />

            <IconButton
                aria-controls="menu-appbar"
                aria-haspopup="true"
                aria-label="Menu"
                onClick={handleOpenNavMenu}
                size="large"
                sx={{ ml: 'auto' }}
            >
                <MenuIcon/>
            </IconButton>

            <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                keepMounted
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                open={Boolean(anchorElNav)}
                onClose={() => handleCloseNavMenu()}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                {renderMobileButtons()}
                <MenuItem>
                    <SubscriptionButton disabled={loading} href={event?.subscription} onClick={handleCloseNavMenu}/>
                </MenuItem>
            </Menu>
        </Box>
    }

    const renderDesktopBox = () => {
        return <Box sx={{
            display: { xs: 'none', md: 'flex' },
            flexGrow: 1,
            width: '100%',
        }}>
            <Box>
                <img
                    alt="logo"
                    height="175px"
                    onClick={() => handleCloseNavMenu('/')}
                    src={logoUrl}
                    style={{ marginRight: '10px', padding: '10px' }}
                    width="175px"
                />
            </Box>

            <Box sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-evenly',
                width: '90%',
                '& a': {},
            }}>
                {renderDesktopButtons()}
                <SubscriptionButton disabled={loading} href={event?.subscription}/>
            </Box>
        </Box>
    }

    const renderMobileButtons = () => {
        return pages.map(page => renderMobileButton(page))
    }

    const renderDesktopButtons = () => {
        return pages.map(page => renderDesktopButton(page))
    }

    const renderMobileButton = ({ target, title }: { target: string, title: string }) => {
        return <MenuItem key={target} onClick={() => handleCloseNavMenu(target)} sx={{
            color: currentPage === target ? '#000!important' : '',
            fontWeight: currentPage === target ? 800 : 400,
        }}>
            {title}
        </MenuItem>
    }

    const renderDesktopButton = ({ target, title }: { target: string, title: string }) => {
        return <Button
            key={target}
            onClick={() => handleCloseNavMenu(target)}
            sx={{
                borderBottom: currentPage === target ? `2px solid` : 0,
                borderColor: 'secondary.main',
                borderRadius: 0,
                color: currentPage === target ? 'secondary.main' : '',
                display: 'flex',
                fontSize: '1.1rem',
                fontWeight: currentPage === target ? 800 : 400,
                height: '65px',
                p: 1,
            }}
        >
            {title}
        </Button>
    }

    return (
        <AppBar position="sticky" sx={{
            bgcolor: 'background.main',
            boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, .14), 0px 1px 10px 0px rgba(0, 0, 0, .12)',
            height: { xs: '95px', md: '199px' },
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{}}>
                    {renderMobileBox()}
                    {renderDesktopBox()}
                </Toolbar>
            </Container>
        </AppBar>
    )
}
