import * as React from 'react'
import {
    Create, ImageField, ImageInput, NumberInput, SelectInput, SimpleForm, TextInput, useTranslate,
} from 'react-admin'
import { useParams } from 'react-router-dom'
import { validateUploadImage } from '../../../utils/utils'
import { Toolbar } from '../common'

export const CreateImage = () => {
    const translate = useTranslate()
    const { eventId } = useParams()

    const redirect = `/admin/events/${eventId}/images`
    const resource = `events/${eventId}/images`

    const choices = [
        { id: 'carousel' },
        { id: 'gallery' },
    ].map(item => ({
        ...item,
        name: translate(`image.type.${item.id}`),
    }))

    const params = {
        redirect,
        resource,
        title: 'Cadastrar Imagem',
    }

    return (
        <Create {...params}>
            <SimpleForm toolbar={<Toolbar redirect={redirect}/>} validate={validateUploadImage('url')}>
                <TextInput variant="outlined" label="Título" source="title" fullWidth required/>
                <TextInput variant="outlined" label="Descrição" source="alt" fullWidth required/>
                <SelectInput variant="outlined" label="Tipo" source="type" choices={choices} fullWidth required/>
                <NumberInput variant="outlined" label="Ordem" source="order" fullWidth/>
                <NumberInput variant="outlined" label="Ano" source="year" fullWidth/>
                <ImageInput variant="outlined" label="Imagem" source="image_url" fullWidth isRequired sx={{
                    '& .RaFileInput-removeButton': {
                        display: 'block',
                        float: 'inherit',
                        '& button': { opacity: 1 },
                    },
                    '& img': { width: '350px' },
                }}>
                    <ImageField source="url" title="title" sx={{
                        display: 'flex!important',
                        justifyContent: 'center',
                        width: 'full',
                    }}/>
                </ImageInput>
            </SimpleForm>
        </Create>
    )
}
