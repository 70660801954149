import * as React from 'react'
import { Menu, MenuItemLink } from 'react-admin'
import { Event, Settings } from '@mui/icons-material'

const CustomMenu = (props: any) => (
    <Menu {...props}>
        <MenuItemLink to="/admin/events" primaryText="Eventos" leftIcon={<Event/>}/>
        <MenuItemLink to="/admin/settings" primaryText="Configurações" leftIcon={<Settings/>}/>
    </Menu>
)

export default CustomMenu
