import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { EventDto } from '../dtos'
import { StyledBox } from '../components'

export function Event({ event }: { event: EventDto|undefined }) {
    return <StyledBox disableGutters="xs" sx={{ display: 'flex', pt: { xs: 5, md: 0 }, pb: 0 }}>
        <Grid container spacing={2} sx={{ alignSelf: 'center' }}>
            <Grid display="flex" item xs={12} md={6} sx={{ p: 0 }}>
                <Box sx={{ px: 5 }}>
                    <Typography variant="h3">O Evento</Typography>

                    <Typography sx={{ pb: { xs: 2, md: 2 } }}>
                        {event?.subtitle}
                    </Typography>

                    <Typography>
                        {event?.description}
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} md={6} sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center',
                width: { xs: '100vw', md: 'inherit' },
                '& img': {
                    maxHeight: { xs: 'calc(90vh - 95px)', md: 'calc(85vh - 199px)' },
                    maxWidth: { xs: '100vw', md: '35vw' },
                }
            }}>
                <img
                    alt="Banner"
                    loading="lazy"
                    src={event?.banner}
                    title="Banner"
                />
            </Grid>
        </Grid>
    </StyledBox>
}
