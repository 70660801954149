import React from 'react'
import { createTheme } from '@mui/material/styles'
import themeConfig from './config/themeConfig'
import { ThemeProvider as DefaultThemeProvider } from '@mui/styles'

export function ThemeProvider(props: any) {
    return <DefaultThemeProvider theme={createTheme(themeConfig)}>
        {props.children}
    </DefaultThemeProvider>
}
