import React from 'react'
import { Box } from '@mui/material'
import themeConfig from '../config/themeConfig'

export type StyledBoxProps = { disableGutters: boolean|'xs'|'md', lowGutters: boolean }&any

export function StyledBox({ disableGutters = false, lowGutters = false, ...props }: StyledBoxProps) {
    const sx = {
        background: themeConfig.palette.linearGradient.main,
        ...props.sx,
        '& h3': {
            fontSize: { xs: '2.5rem', md: '3rem' },
            pb: 5,
            pt: { xs: 1, md: 5 },
            px: { xs: 3, md: 0 },
            ...props.sx?.['& h3'],
        },
        '& p': {
            color: 'primary.main',
            fontSize: '1.1rem',
            fontWeight: 700,
            textAlign: { xs: 'center', md: 'left' },
            ...props.sx?.['& p'],
        },
        '& :is(h1, h2, h3, h4, h5, h6)': {
            color: 'secondary.main',
            fontWeight: 800,
            ...props.sx?.['& :is(h1, h2, h3, h4, h5, h6)'],
        },
    }

    if (lowGutters) {
        sx.p = sx.p ?? '0.5rem'
        sx.minHeight = { xs: 'calc(100vh - 64px - 95px - 12px)', md: 'calc(100vh - 64px - 199px - 12px)', ...sx.minHeight }
    }

    if (!['xs', true].includes(disableGutters)) {
        sx.pt = { xs: 5, ...sx.pt }
        sx.px = { xs: 0, ...sx.px }
        sx.pb = { xs: 2, ...sx.pb }
        sx.minHeight = { xs: 'calc(100vh - 64px - 95px - 40px - 16px)', ...sx.minHeight }
        sx['& > div'] = { mx: { xs: 2 }, ...sx['& > div'] }
    }

    if (!['md', true].includes(disableGutters)) {
        sx.pt = { md: 0, ...sx.pt }
        sx.px = { md: 20, ...sx.px }
        sx.pb = { md: 5, ...sx.pb }
        sx.minHeight = { md: 'calc(100vh - 64px - 199px - 40px)', ...sx.minHeight }
        sx['& > div'] = { mx: { md: 0 }, ...sx['& > div'] }
    }

    if (!sx.minHeight) {
        sx.minHeight = { xs: 'calc(100vh - 64px - 95px)', md: 'calc(100vh - 64px - 199px)' }
    }

    return <Box sx={sx}>{props.children}</Box>
}
