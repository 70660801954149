export declare type dictionary = { [key: string]: string|dictionary }

const locale = (base: any): dictionary => ({
    ...base,
    image: {
        message: {
            imageRequired: 'É necessário selecionar uma imagem',
            uploadFailed: 'Erro ao subir imagem',
        },
        type: {
            carousel: 'Carrossel',
            gallery: 'Galeria',
        },
    },
    ra: {
        ...base.ra,
        action: {
            ...base.ra.action,
            create: 'Cadastrar',
        },
    },
})

export default locale
