import * as React from 'react'
import { Edit, FileField, FileInput, ImageField, ImageInput, NumberInput, SimpleForm, TextInput } from 'react-admin'
import { Toolbar, transform } from '../common'
import { useParams } from 'react-router-dom'
import { getBase64, validateUploadImage } from '../../../utils/utils'

const customTransform = async (data: any) => {
    if (data.document && typeof data.document === 'object') {
        data.document = await getBase64(data.document)
    } else {
        delete data.document
    }

    if (!data.image) {
        delete data.image
    }

    return transform(data)
}

export const EditResult = () => {
    const { eventId } = useParams()

    const redirect = `/admin/events/${eventId}/results`

    const params = {
        mutationMode: 'pessimistic',
        redirect,
        resource: 'results',
        title: 'Edição de Resultado',
        transform: customTransform,
    } as any

    return (
        <Edit {...params}>
            <SimpleForm toolbar={<Toolbar redirect={redirect}/>} validate={validateUploadImage('image', false)}>
                <TextInput variant="outlined" label="Título" source="title" fullWidth required/>
                <TextInput variant="outlined" label="Primeiro ganhador" source="one" fullWidth/>
                <TextInput variant="outlined" label="Segundo ganhador" source="two" fullWidth/>
                <TextInput variant="outlined" label="Terceiro ganhador" source="three" fullWidth/>
                <TextInput variant="outlined" label="Quarto ganhador" source="four" fullWidth/>
                <TextInput variant="outlined" label="Quinto ganhador" source="five" fullWidth/>
                <NumberInput variant="outlined" label="Ordem" source="order" fullWidth/>

                <ImageInput variant="outlined" label="Imagem" source="image_image" fullWidth sx={{
                    '& .RaFileInput-removeButton': {
                        display: 'block',
                        float: 'inherit',
                        '& button': { opacity: 1 },
                    },
                    '& img': { width: '350px' },
                }}>
                    <ImageField source="image" title="title" sx={{
                        display: 'flex!important',
                        justifyContent: 'center',
                        width: 'full',
                    }}/>
                </ImageInput>

                <FileInput variant="outlined" label="Arquivo" source="document" fullWidth>
                    <FileField source="src" title="title"/>
                </FileInput>
            </SimpleForm>
        </Edit>
    )
}
