import * as React from 'react'
import { DateField, ImageField, NumberField, Show, SimpleShowLayout, TextField } from 'react-admin'

export const ShowEvent = () => (
    <Show title="Evento">
        <SimpleShowLayout>
            <TextField label="Sub título" source="subtitle"/>
            <TextField label="Descrição" source="description"/>
            <NumberField label="Edição" source="edition"/>
            <DateField label="Data de lançamento" source="launch_at" showTime={true} locales="pt-BR"/>
            <DateField label="Data de término" source="end_at" showTime={true} locales="pt-BR"/>
            <TextField label="Link de inscrição" source="subscription"/>
            <ImageField label={'Banner "O Evento"'} source="event_banner" sx={{ '& img': { width: '500px' } }}/>
            <ImageField label="Fundo para desktop" source="background_desktop" sx={{ '& img': { width: '500px' } }}/>
            <ImageField label="Fundo para mobile" source="background_mobile" sx={{ '& img': { width: '500px' } }}/>
        </SimpleShowLayout>
    </Show>
)
