import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'
import { Download } from '@mui/icons-material'

export interface DownloadPdfButtonProps extends ButtonProps {
    base64?: string|false,
    children: any,
    fileName?: string,
}

export interface DownloadButtonProps extends ButtonProps {
    children: any,
    download: string,
    href: string|false|any,
}

export function DownloadPdfButton({ base64, children, fileName, ...props }: DownloadPdfButtonProps) {
    if (base64 === undefined || !fileName) return <></>

    fileName = fileName.endsWith('.pdf') ? fileName : `${fileName}.pdf`

    const clearBase64 = (data?: string|false) => {
        if (!data) return data

        data.replace(/data:\w+\/\w+;base64,/, '')
        return `data:application/octet-stream;base64,${data}`
    }

    return <DownloadButton download={fileName} href={clearBase64(base64)} {...props as any}>
        {children}
    </DownloadButton>
}

export function DownloadButton({ children, download, href, ...props }: DownloadButtonProps) {
    download = download.endsWith('.pdf') ? download : `${download}.pdf`

    const params = {
        download,
        ...(href && { href }),
        disabled: !href,
        ...props,
        sx: {
            bgcolor: 'background.red',
            borderRadius: '30px',
            color: 'primary.contrastText',
            justifyItems: 'between',
            minWidth: { xs: '100px', md: '350px' },
            maxWidth: 'calc(100vw - 32px)',
            position: 'relative',
            px: 4,
            pt: 1,
            width: { xs: '100%', md: 'fit-content' },
            '&:hover': { bgcolor: 'background.main', color: 'secondary.main' },
            '& span > span': {
                color: 'primary.contrastText',
                fontSize: '1.5rem',
                height: '1em!important',
                width: '1em!important',
            },
            ...props.sx,
        }
    }

    const renderIcon = (href?: string|false) => {
        if (!href) return <CircularProgress/>

        return <Download/>
    }

    return <Button {...params}>
        {children} <span style={{ position: 'absolute', right: 8, top: 'calc(50% - 0.75rem)' }}>{renderIcon(href)}</span>
    </Button>
}
