import * as React from 'react'
import { useEffect } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { useGetIdentity, useLogin, useNotify, useRedirect } from 'react-admin'
import themeConfig from '../config/themeConfig'

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Copyright &copy; {new Date().getFullYear()} Mini Race Brasil
        </Typography>
    )
}

export function Login() {
    const login = useLogin()
    const notify = useNotify()
    const redirect = useRedirect()
    const { identity, isLoading: isIdentityLoading } = useGetIdentity()

    useEffect(() => {
        if (!isIdentityLoading && identity?.token) {
            redirect('/admin')
        }
    }, [identity, isIdentityLoading, redirect])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)

        login({
            email: data.get('email'),
            password: data.get('password'),
        }).catch(() => {
            notify('Credenciais incorretas!')
        })
    }

    return (
        <ThemeProvider theme={createTheme(themeConfig)}>
            <Container component="main" sx={{
                alignItems: 'center',
                backgroundImage: 'url(/img/login_bg.webp)',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                marginX: '0',
                maxWidth: '100vw!important',
                width: '100vw',
            }}>
                <CssBaseline/>

                <Box sx={{
                    alignItems: 'center',
                    bgcolor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '25px',
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 'calc(50vh - (385px / 2))',
                    padding: '20px',
                }}>
                    <Avatar sx={{
                        m: 1,
                        bgcolor: 'secondary.main',
                    }}>
                        <LockOutlinedIcon/>
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Endereço de e-mail"
                            name="email"
                            autoComplete="email"
                            autoFocus
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Senha"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                            }}
                        >
                            Entrar
                        </Button>
                    </Box>

                    <Copyright sx={{
                        mt: 2,
                        mb: 2,
                    }}/>
                </Box>
            </Container>
        </ThemeProvider>
    )
}
