import React, { useEffect, useState } from 'react'
import './App.css'
import { Loading, Navbar } from './app/components'
import { Box } from '@mui/material'
import { Route, Routes } from 'react-router-dom'
import { EventDto, ImageDto, ScheduleDto, SettingsDto, TermDto } from './app/dtos'
import apiService from './app/services/api.service'
import { Contact, Event, Gallery, Home, Regulation, Results, Schedule } from './app/pages'
import { createTheme, experimental_sx as sx, ThemeProvider as DefaultThemeProvider } from '@mui/material/styles'
import themeConfig from './app/config/themeConfig'
import { ThemeProvider } from './app/ThemeProvider'
import { styled } from '@mui/styles'
import { ResultDto } from './app/dtos/result.dto'
import { Footer } from './app/components/Footer'

const CustomBox = styled(Box)(sx({
    minHeight: '100vh',
    '& .AppContent': {
        display: 'flex',
        minHeight: { xs: 'calc(100vh - 95px - 64px)', md: 'calc(100vh - 199px - 64px)' },
        '& > div': {
            width: '100%',
        },
        '& .Loading': {
            '& .RaLoading-message': {
                opacity: 1,
                '& :is(div, svg, h1)': {
                    color: 'primary.contrastText',
                },
            },
        },
    },
}) as any)

export function App() {
    const [event, setEvent] = useState<EventDto|undefined>()
    const [loadingEvent, setLoadingEvent] = useState(true)

    const [carousel, setCarousel] = useState<ImageDto[]>([])

    const [gallery, setGallery] = useState<ImageDto[]>([])
    const [loadingGallery, setLoadingGallery] = useState(true)

    const [schedules, setSchedules] = useState<ScheduleDto[]>([])
    const [loadingSchedules, setLoadingSchedules] = useState(true)

    const [terms, setTerms] = useState<TermDto|undefined>()
    const [loadingTerms, setLoadingTerms] = useState(true)

    const [results, setResults] = useState<ResultDto[]>([])
    const [loadingResults, setLoadingResults] = useState(true)

    useEffect(() => {
        apiService.getSettings().then(settings => {
            getData(settings)
        })
    }, [])

    const getData = (settings: SettingsDto) => {
        apiService.getEvent(settings.event).then(event => {
            setEvent(event)
            setLoadingEvent(false)
        })

        apiService.getEventCarousel(settings.event).then(carousel => {
            setCarousel(carousel)
        })

        apiService.getEventGallery(settings.event).then(gallery => {
            setGallery(gallery)
            setLoadingGallery(false)
        })

        apiService.getEventSchedules(settings.event).then(schedules => {
            setSchedules(schedules)
            setLoadingSchedules(false)
        })

        apiService.getEventTerms(settings.event).then(terms => {
            setTerms(terms)
            setLoadingTerms(false)
        })

        apiService.getEventResults(settings.event).then(results => {
            setResults(results)
            setLoadingResults(false)

            return Promise.all(results.map(async result => apiService.getResultDoc(result.id)))
        }).then(results => {
            setResults(results)
        })
    }

    const renderContent = () => {
        if (loadingEvent) return <Loading/>

        const params = {
            home: { carousel, event, loading: loadingEvent },
            event: { event },
            schedule: { loading: loadingSchedules, schedules },
            gallery: { gallery, loading: loadingGallery },
            regulation: { loading: loadingTerms, terms },
            results: { loading: loadingResults, results },
        }

        return (
            <Routes>
                <Route index element={<Home {...params.home}/>}/>
                <Route path="evento" element={<Event {...params.event}/>}/>
                <Route path="cronograma" element={<Schedule {...params.schedule}/>}/>
                <Route path="galeria" element={<Gallery {...params.gallery}/>}/>
                <Route path="regulamento" element={<Regulation {...params.regulation}/>}/>
                <Route path="resultados" element={<Results {...params.results}/>}/>
                <Route path="contato" element={<Contact/>}/>
            </Routes>
        )
    }

    return (
        <DefaultThemeProvider theme={createTheme(themeConfig)}>
            <ThemeProvider>
                <CustomBox className="App">
                    <Navbar event={event} loading={loadingEvent}/>

                    <Box className="AppContent">
                        {renderContent()}
                    </Box>

                    <Footer/>
                </CustomBox>
            </ThemeProvider>
        </DefaultThemeProvider>
    )
}
