import * as React from 'react'
import { Create, FileField, FileInput, SimpleForm } from 'react-admin'
import { useParams } from 'react-router-dom'
import { getBase64 } from '../../../utils/utils'
import { Toolbar, transform } from '../common'

const customTransform = async (data: any) => {
    if (data.disclaimer && typeof data.disclaimer === 'object') {
        data.disclaimer = await getBase64(data.disclaimer)
    } else {
        delete data.disclaimer
    }

    if (data.regulation && typeof data.regulation === 'object') {
        data.regulation = await getBase64(data.regulation)
    } else {
        delete data.regulation
    }

    return transform(data)
}

export function Terms() {
    const { eventId } = useParams()

    const params = {
        redirect: `/admin/events`,
        resource: `events/${eventId}/terms`,
        title: 'Cadastrar Termos',
        transform: customTransform,
    }

    return (
        <Create {...params}>
            <SimpleForm toolbar={<Toolbar/>}>
                <FileInput variant="outlined" label="Regulamento" source="regulation" fullWidth>
                    <FileField source="src" title="title"/>
                </FileInput>

                <FileInput variant="outlined" label="Termo de responsabilidade e cessão de direitos de imagem" source="disclaimer" fullWidth>
                    <FileField source="src" title="title"/>
                </FileInput>
            </SimpleForm>
        </Create>
    )
}
